<template>
  <ais-instant-search index-name="tasoulis_new" :search-client="searchClient">
    <div class="right1-panel">
      <ais-autocomplete class="el">
        <template v-slot="{ indices }">
          <div class="test-sea">
            <ais-search-box
              id="autocomplete"
              placeholder="Αναζήτηση..."
              ref="targetElement"
            />
            <button class="sear" @click="fco()">
              <img src="/wp-content/uploads/2023/10/search-n1.png" />
            </button>
          </div>
          <div>
            <ais-configure
              query=""
              :disjunctive-facets-refinements.camel="{
                Lang: ['el'],
              }"
            />
          </div>
          <div v-if="test">
            <ais-configure
              query=""
              :disjunctive-facets-refinements.camel="{
                Lang: ['en'],
              }"
            />
          </div>
          <ul
            style="background-color: rgb(255 255 255); margin-top: 0px"
            v-for="index in indices"
            :key="index.indexId"
            class="res"
          >
            <li v-if="focus === true">
              <ul class="res1">
                <p v-if="index.hits.length == 0">Κανένα Αποτέλεσμα</p>
                <li
                  v-for="hit in index.hits"
                  :key="hit.objectID"
                  style="margin-bottom: 20px"
                >
                  <ais-highlight attribute="name" :hit="hit" />
                  <a
                    :href="hit.Slug"
                    style="margin-top: 0px; color: black; text-decoration: none; display: flex; gap: 20px; align-items: center;}"
                  >
                    <img
                      style="
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                        background-color: #fff;
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                      "
                      :src="hit.Images"
                    />
                    {{ hit.onoma }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </ais-autocomplete>
      <ais-autocomplete class="en">
        <template v-slot="{ indices }">
          <div class="test-sea">
            <ais-search-box
              id="autocomplete"
              placeholder="Search..."
              ref="targetElement"
            />
            <button class="sear" @click="fco1()">
              <img src="/wp-content/uploads/2023/10/search-n1.png" />
            </button>
          </div>
          <ul
            style="background-color: rgb(255 255 255); margin-top: 0px"
            v-for="index in indices"
            :key="index.indexId"
            class="res"
          >
            <li v-if="focus === true">
              <ul class="res1">
                <p v-if="index.hits.length == 0">No Result</p>
                <li
                  v-for="hit in index.hits"
                  :key="hit.objectID"
                  style="margin-bottom: 20px"
                >
                  <ais-highlight attribute="name" :hit="hit" />
                  <a
                    :href="hit.Slug"
                    style="margin-top: 0px; color: black; text-decoration: none; display: flex; gap: 20px; align-items: center;}"
                  >
                    <img
                      style="
                        width: 60px;
                        height: 60px;
                        object-fit: cover;
                        background-color: #fff;
                        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                      "
                      :src="hit.Images"
                    />
                    {{ hit.onoma }}
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </template>
      </ais-autocomplete>
    </div>
  </ais-instant-search>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/algolia-min.css";
import "vue-slider-component/theme/antd.css";
export default {
  data() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);
    var catigoria1 = params.get("category");
    var cild = false;
    var focus = false;
    var focus1 = false;
    var test = window.location.href.includes("/en");

    return {
      catigoria1: catigoria1,
      focus: focus,
      focus1: focus1,
      cild: cild,
      test: test,
      searchClient: algoliasearch(
        "WU7SY8RYJ6",
        "fcabccf450f1d8575f70a6cf6c745765"
      ),
    };
  },
  methods: {
    handleClickOutside(event) {
      const test = event.target;
      const myElement = document.getElementsByClassName("res1");
      console.log(myElement.firstChild);
      if (test.placeholder == "Αναζήτηση...") {
        this.focus = true;
      } else if (test.placeholder == "Search...") {
        this.focus = true;
      } else {
        this.focus = false;
      }
    },
    fco() {
      const input = document.getElementById("autocomplete");
      let firstChild = input.querySelector("input").value;
      console.log(firstChild);
      window.location = "https://tasoulis-jewels.gr/search/?sr=" + firstChild;
    },
    fco1() {
      const input = document.getElementById("autocomplete");
      let firstChild = input.querySelector("input").value;
      console.log(firstChild);
      window.location =
        "https://tasoulis-jewels.gr/en/search/?sr=" + firstChild;
    },

    toValue(value, range) {
      return [
        typeof value.min === "number" ? value.min : range.min,
        typeof value.max === "number" ? value.max : range.max,
      ];
    },
    indicesToSuggestions(indices) {
      return indices.map(({ hits }) => ({ data: hits }));
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
    const input = document.getElementById("autocomplete");
    let autocomplete = input.querySelector("input");
    autocomplete.addEventListener("keypress", function (event) {
      if (event.key === "Enter") {
        event.preventDefault();
        window.location =
          "https://tasoulis-jewels.gr/search/?sr=" + autocomplete.value;
      }
    });
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style>
.ais-Autocomplete .ais-SearchBox-submit,
.ais-Autocomplete .ais-SearchBox-reset {
  display: none !important;
}

.sear {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 40px;
  width: 50px;
  z-index: 99999;
  background-color: transparent;
  box-shadow: none;
  border: 0px solid;
  border-top-right-radius: 24px;
  cursor: pointer;
  border-bottom-right-radius: 24px;
}

.sear img {
  width: 50%;
  height: auto;
  filter: invert(14%) sepia(55%) saturate(4392%) hue-rotate(331deg)
    brightness(83%) contrast(95%);
}

.test-sea {
  position: relative;
}
.res a:hover {
  color: #8d1633 !important;
}
.res,
.res ul {
  list-style-type: none;
  padding-left: 0px;
  border-radius: 24px;
}
.res1 p {
  margin-bottom: 0px !important;
}
.res ul {
  border-top: 1px solid #e8eaed;
  max-height: 300px;
  width: 290px;
  overflow-y: auto;
  padding: 30px 30px;
  border-radius: 0 0 24px 24px;
  /*box-shadow: 0 9px 8px -3px rgba(64, 60, 67, 0.24),
    8px 0 8px -7px rgba(64, 60, 67, 0.24),
    -8px 0 8px -7px rgba(64, 60, 67, 0.24);*/
  margin-top: -20px;
}
.ais-Autocomplete {
  box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
  border-radius: 24px;
}
#autocomplete input {
  width: 350px;
  border: 1px solid transparent;
}

#autocomplete input:focus {
  outline: none;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  box-shadow: none;
  border-bottom: 1px solid #e8eaed;
  box-shadow: 0px !important;
}

#autocomplete {
  margin-bottom: 0px;
}

.ais-InstantSearch {
  display: flex !important;
}

.ais-InfiniteHits-item,
.ais-InfiniteResults-item,
.ais-Hits-item,
.ais-Results-item {
  width: 30% !important;
}

.ais-SearchBox-input,
.ais-SearchBox-submit {
  display: none;
}
.right1-panel .ais-SearchBox-input,
.right1-panel .ais-SearchBox-submit {
  display: block;
}

.ais-SearchBox-input {
  padding: 10px 20px;
  border-radius: 20px;
  background: #f6f6f6 !important;
}

@media only screen and (max-width: 992px) {
}
</style>
